<template>
    <DeliveryPage />
</template>

<script>
import DeliveryPage from '../components/Terms/DeliveryPage.vue'

export default {
    components: {
        DeliveryPage
    },
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    },
}
</script>

